import React from 'react'

function Error404() {
    return (
        <div>
            <h1>error</h1>
        </div>
    )
}

export default Error404
